body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Fira Code", Menlo, Monaco, Consolas, "Courier New", monospace;
}

.black-white-filter {
  filter: grayscale(100%) contrast(120%);
  -webkit-filter: grayscale(100%) contrast(120%);
}

section {
  height: fit-content;
  width: 100%;
  padding: 1em;
  overflow-x: hidden;
}

#landing {
  height: 100vh;
  overflow-y: hidden;
}

#about {
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
}

#projects {
  padding-top: 16rem;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 10%);
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 10%);
}

#footer {
  height: 300px;
}

* {
  scroll-behavior: smooth;
}
