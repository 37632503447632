.p-sidebar {
  border: none;
  backdrop-filter: blur(1.5rem);
}

.p-sidebar-content {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.p-sidebar-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
